import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GetMyProfile } from "../../HttpServices/teamManagerHttpServices/teamManagerHttpServices";
import { ChangePassword, GetNotification } from "../../HttpServices/studentHttpServices/studentHttpServices";
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setuserData] = useState([]);
  const [activeStatus, setactiveStatus] = useState("")
  const [waiting, setwaiting] = useState(false)
  const [notiData, setnotiData] = useState([])
  const [notiWaiting, setnotiWaiting] = useState(false)
  const navigate = useNavigate();
  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log me out!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("leagueToken");
        localStorage.removeItem("role");
        localStorage.removeItem("leagueLanguage");
        setIsLoggedIn(false);
        navigate("/"); // or navigate to the homepage
        Swal.fire("Logged Out!", "", "success");
      }
    });
  };
  const getMyProfile = async () => {
    try {
      setwaiting(true)
      const response = await GetMyProfile();
      setuserData(response?.data?.results?.profile);
      setactiveStatus(response?.data?.results?.profile?.details?.activeStatus)
      setwaiting(false)
      console.log(response);
    } catch (error) {
      console.log(error.message);
    }finally{
      setwaiting(false)
    }
  };
  const getMyNotifications = async () => {
    try {
      setnotiWaiting(true)
      const response = await GetNotification();
      // setnotiData(response?.data?.results?.profile);
      setnotiWaiting(false)
      console.log(response);
    } catch (error) {
      console.log(error.message);
    }finally{
      setnotiWaiting(false)
    }
  };

  
  return (
    <userAuthContext.Provider
      value={{
        logout,
        getMyProfile,
        userData,
        isLoggedIn,
        activeStatus,
        waiting,
        getMyNotifications
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
