import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const PROTECTED_ROUTES = {
  alumni: "/Alumni",
  student: "/Student",
  teamManager: "/TeamManager",
  coach: "/Coach",
};

const ROLES = {
  alumni: "alumni",
  student: "student",
  teamManager: "manager",
  coach: "coach",
};

// Exception path: Public access for /Student/PrizeDistribution/:id
const PUBLIC_ROUTE = /^\/Student\/PrizeDistribution\/\d+$/;

const ProtectedRoutes = ({ children, roleBasedPages = [] }) => {
  const location = useLocation();
  const roles = localStorage.getItem("role");

  // Check if the current path is the public route
  const isPublicRoute = PUBLIC_ROUTE.test(location.pathname);

  const hasAccess = (role, path) =>
    roles?.includes(role) && location.pathname.startsWith(path);

  const showWarningAndRedirect = (title, text) => {
    Swal.fire({
      icon: "warning",
      title,
      text,
      showConfirmButton: false,
      timer: 2500,
    });
    return <Navigate to="/" state={{ from: location }} replace />;
  };

  const roleBasedRedirection = () => {
    if (roles) {
      const roleHomePath = `/${roles}/Home`;
      // Redirect to home if accessing login or registration pages
      if (roleBasedPages.some(page => location.pathname.startsWith(page))) {
        return <Navigate to={roleHomePath} replace />;
      }
    } else if (roleBasedPages.some(page => location.pathname.startsWith(page))) {
      // If no role and trying to access role-based page, show warning
      return showWarningAndRedirect("Login Please", "You are not logged in!");
    }
    return null;
  };

  // Skip protection for the public route
  if (isPublicRoute) {
    return children;
  }

  const redirect = roleBasedRedirection();
  if (redirect) return redirect;

  const userHasAccess = Object.entries(PROTECTED_ROUTES).some(([role, path]) =>
    hasAccess(ROLES[role], path)
  );

  if (!userHasAccess) {
    return showWarningAndRedirect(
      "Access denied",
      "You are not authorized to access this page"
    );
  }

  return children;
};

export default ProtectedRoutes;
