import React, { useEffect } from "react";
import { useUserAuth } from "../../Authentications/Others/AuthContext";
import { Link } from "react-router-dom";
const CoachHomeNav = () => {
  const { logout, getMyProfile, userData } = useUserAuth();
  useEffect(() => {
    getMyProfile();
  }, []);
  return (
    <>
      <header className="header_main coach_header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3">
              <Link to="/Coach/Home" className="logo_web">
                <img src="../assets/img/comman/logo.png" alt />
              </Link>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="dropdown language_drop">
                    <button
                      className
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="../assets/img/comman/flag.svg" alt /> EN{" "}
                      <img src="../assets/img/comman/arroww_down.svg" alt />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <a className="language_a" href="javascript:;">
                        <img src="../assets/img/comman/flag.png" alt />
                        Israel
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-auto ps-4">
                  <a className="chat_btn" href="chat.html">
                    <img src="../assets/img/comman/message.png" alt />
                  </a>
                </div>
                <div className="col-auto px-4">
                  <div className="dropdown notification_dropdown">
                    <button
                      className="notification_btn"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src="../assets/img/comman/bell.png" alt />
                      <span />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="notification_head">Notification</div>
                        </div>
                        <div className="col-md-12">
                          <div className="row notification_row">
                            <div className="col-auto">
                              <div className="notification_user">
                                <img src="../assets/img/comman/icon.png" alt />
                              </div>
                            </div>
                            <div className="col">
                              <div className="notification_content">
                                <h3>Dianna Smiley</h3>
                                <p>
                                  You have just recevied new booking id #123456
                                </p>
                                <span>2 m ago</span>
                              </div>
                            </div>
                          </div>
                          <div className="row notification_row">
                            <div className="col-auto">
                              <div className="notification_user">
                                <img src="../assets/img/comman/icon.png" alt />
                              </div>
                            </div>
                            <div className="col">
                              <div className="notification_content">
                                <h3>Dianna Smiley</h3>
                                <p>
                                  You have just recevied new booking id #123456
                                </p>
                                <span>2 m ago</span>
                              </div>
                            </div>
                          </div>
                          <div className="row notification_row">
                            <div className="col-auto">
                              <div className="notification_user">
                                <img src="../assets/img/comman/icon.png" alt />
                              </div>
                            </div>
                            <div className="col">
                              <div className="notification_content">
                                <h3>Dianna Smiley</h3>
                                <p>
                                  You have just recevied new booking id #123456
                                </p>
                                <span>2 m ago</span>
                              </div>
                            </div>
                          </div>
                          <div className="row notification_row">
                            <div className="col-auto">
                              <div className="notification_user">
                                <img src="../assets/img/comman/icon.png" alt />
                              </div>
                            </div>
                            <div className="col">
                              <div className="notification_content">
                                <h3>Dianna Smiley</h3>
                                <p>
                                  You have just recevied new booking id #123456
                                </p>
                                <span>2 m ago</span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-center">
                              <a className="See_moore" href="javascript:;">
                                See More
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown profile_dropdown">
                    <button
                      className="profile_header"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          userData?.profileimage ||
                          "../assets/img/comman/user.svg"
                        }
                        alt
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link className="dropdown-item" to="/Coach/Profile">
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/Settings" className="dropdown-item">
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/Terms" className="dropdown-item">
                          Terms &amp; Conditions
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/AboutUs" className="dropdown-item">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/Privacy" className="dropdown-item">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/Coach/HelpSupport" className="dropdown-item">
                          Help &amp; Support
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <a class="dropdown-item" onClick={() => logout()}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default CoachHomeNav;
