import React from "react";

const CoachHomeFooter = () => {
  return (
    <>
      <footer className="student_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="st_footer_about">
                <a href="javascript:;">
                  <img src="../assets/img/comman/logo.png" alt />
                </a>
                <p>
                  Join us for an engaging session filled with strategic
                  discussions, challenging matches, and insightful analysis.
                </p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <div className="st_footer_menu">
                    <h2>Customer Services</h2>
                    <div className="stfooter_links">
                      <a href="javascript:;">About Us</a>
                      <a href="javascript:;">Terms &amp; Conditions</a>
                      <a href="javascript:;">Privacy Policy</a>
                      <a href="javascript:;">E-Waste Policy</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="st_footer_menu">
                    <h2>Customer Services</h2>
                    <div className="stfooter_links">
                      <a href="javascript:;">About Us</a>
                      <a href="javascript:;">Terms &amp; Conditions</a>
                      <a href="javascript:;">Privacy Policy</a>
                      <a href="javascript:;">E-Waste Policy</a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="st_support">
                    <div className="row mb-4">
                      <div className="col-auto">
                        <div className="st_support_icon">
                          <img src="../assets/img/comman/Email__st.svg" alt />
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="st_support_details">
                          <span>Email:</span>
                          <a href="mailto:contact@mindleague.com">
                            contact@mindleague.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <div className="st_support_icon">
                          <img src="../assets/img/comman/Phone_st.svg" alt />
                        </div>
                      </div>
                      <div className="col ps-0">
                        <div className="st_support_details">
                          <span>Phone:</span>
                          <a href="tel:(345) 678 - 2345">(345) 678 - 2345</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row social_part justify-content-center">
            <div className="col-auto px-3">
              <a href="javascript:;">Facebook</a>
            </div>
            <div className="col-auto px-3">
              <a href="javascript:;">LinkedIn</a>
            </div>
            <div className="col-auto px-3">
              <a href="javascript:;">Instagram</a>
            </div>
            <div className="col-auto px-3">
              <a href="javascript:;">Twitter</a>
            </div>
            <div className="col-auto px-3">
              <a href="javascript:;">Youtube</a>
            </div>
          </div>
          <div className="row pb-4">
            <div className="col-12 copyrightt">
              <p>
                Copyright © 2025 BRIX Agency | All Rights Reserved |{" "}
                <a href="javascript:;">Terms and Conditions</a> |{" "}
                <a href="javascript:;">Privacy Policy</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default CoachHomeFooter;
