import React from "react";
import Lottie from "lottie-react";
import animationData from "./Animation - 1710591450469.json";
import gif from "./Loader.gif";
const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <img src={gif} alt="Loading..." />
    </div>
  );
};

export default Loader;
