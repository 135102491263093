import React, { useEffect, useState } from "react";
import CoachHomeNav from "../HomeComponents/CoachHomeNav";
import CoachHomeFooter from "../HomeComponents/CoachHomeFooter";
import {
  GetSchool,
  GetSchoolDetailsById,
} from "../../HttpServices/coachHttpServices/coachHttpServices";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

const IndividualSession = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [newData, setnewData] = useState([]);
  const [sort, setSort] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [search, setsearch] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [viewLoad, setviewLoad] = useState(false);

  const pagesPerChunk = 5;
  const [student, setstudent] = useState([]);

  const getStudent = async (page, search, pageSize, sort) => {
    setLoading(true); // Set loading to true when starting to fetch
    try {
      const response = await GetSchool({
        page, // Use the dynamic page value
        pageSize,
        sortBy: sort,
        search,
      });
      setstudent(response?.data?.results?.schools || []); // Update members state
      setTotalPages(response?.data?.results?.totalPages || 1); // Update total pages
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false); // Set loading to false when done fetching
    }
  };
  useEffect(() => {
    getStudent(currentPage, search, pageSize, sort);
  }, [currentPage, search, pageSize, sort]);

  // Handle page number click
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const getSschoolById = async (id) => {
    try {
      setviewLoad(true);
      const response = await GetSchoolDetailsById(id);
      console.log(response);
      setnewData(response?.data?.results?.profile);
      setviewLoad(false);
    } catch (error) {
      console.log(error?.message);
    } finally {
      setviewLoad(false);
    }
  };

  const endPage = Math.min(startPage + pagesPerChunk - 1, totalPages);
  const loaderStyle = {
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    height: "100vh", // Full viewport height
  };

  return (
    <>
      <CoachHomeNav />
      <section className="studnet_managment coachhead_space">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <nav>
                      <div
                        className="nav nav-tabs outertabs border-0"
                        id="nav-tab"
                        role="tablist"
                      >
                        <Link
                          to="/Coach/SlotManagement"
                          className="nav-link active"
                        >
                          Individual Sessions
                        </Link>
                        <Link to="/Coach/TeamSession" className="nav-link ">
                          Team Sessions
                        </Link>
                      </div>
                    </nav>

                    <div className="col-md-auto pe-0">
                      <div className="row">
                        <div className="col-12">
                          <Link
                            className="commantopbar_btn d-flex"
                            // data-bs-toggle="modal"
                            // data-bs-target="#AddSchedule"
                            to="/Coach/Schedule"
                          >
                            View Calendar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="row comman_table mt-5">
                        <div className="col-12">
                          <div className="row justify-content-between pb-2">
                            <div className="col-md-auto">
                              <div className="row">
                                <div className="col-auto">
                                  <div className="datashow">
                                    <span>Show</span>
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        setpageSize(e.target.value)
                                      } // Pass the selected value
                                      defaultValue="10" // Set default value
                                    >
                                      <option value="10">10</option>
                                      <option value="15">15</option>
                                      <option value="20">20</option>
                                    </select>
                                    <div className="entries">entries</div>
                                  </div>
                                </div>
                                <div className="col-md-6 ps-4">
                                  <form className="table_search" action="#">
                                    <div className="form-group position-relative">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search..."
                                        onChange={(e) =>
                                          setsearch(e.target.value)
                                        }
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-auto">
                              <div className="row">
                                <div className="col-auto Sort_select pe-3">
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={(e) => setSort(e.target.value)} // Pass the selected value
                                    defaultValue="1"
                                  >
                                    <option selected>Sort</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="table_design table_design1">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>
                                      <div className="row align-items-center">
                                        <div className="col-auto"></div>
                                        <div className="col ps-0 text-start">
                                          School Name
                                        </div>
                                      </div>
                                    </th>
                                    <th>Team Manager</th>
                                    <th>Phone Number</th>

                                    <th>Email Address</th>
                                    <th>Club Name</th>
                                    <th>
                                      Total Session <br /> Booked
                                    </th>
                                    <th>
                                      Total Session <br /> Coached
                                    </th>
                                    <th>
                                      Total Session <br /> Owed
                                    </th>
                                    {/* <th>Status</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {student?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td
                                          data-bs-toggle="offcanvas"
                                          href="#offcanvasExample1"
                                          role="button"
                                          onClick={() =>
                                            getSschoolById(item?.id)
                                          }
                                          aria-controls="offcanvasExample"
                                        >
                                          {item?.school}
                                        </td>
                                        <td>
                                          {item?.firstname
                                            ? `${item.firstname || ""} ${
                                                item.lastname
                                              }`.trim()
                                            : "..."}
                                        </td>
                                        <td>
                                          {item?.phonenumber
                                            ? `${item.countrycode || ""} ${
                                                item.phonenumber
                                              }`.trim()
                                            : "..."}
                                        </td>
                                        <td>{item?.email}</td>
                                        <td>Chess Master</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-4">
                          <div className="table_pagination mt-3">
                            <nav aria-label="Page navigation example">
                              <ul className="pagination justify-content-center mb-0 align-items-center">
                                {/* Previous Button */}
                                <li
                                  className={`page-item me-2 ${
                                    currentPage === 1 ? "disabled" : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (currentPage > 1)
                                        handlePageChange(currentPage - 1);
                                    }}
                                  >
                                    Previous
                                  </a>
                                </li>

                                {/* Page Numbers */}
                                {[...Array(endPage - startPage + 1).keys()].map(
                                  (index) => {
                                    const page = startPage + index;
                                    return (
                                      <li key={page} class="page-item">
                                        <a
                                          className={`page-link ${
                                            currentPage === page ? "active" : ""
                                          }`}
                                          href="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handlePageChange(page);
                                          }}
                                        >
                                          {page}
                                        </a>
                                      </li>
                                    );
                                  }
                                )}

                                {/* Next Button */}
                                <li
                                  className={`page-item ms-2 ${
                                    currentPage === totalPages ? "disabled" : ""
                                  }`}
                                >
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (currentPage < totalPages)
                                        handlePageChange(currentPage + 1);
                                    }}
                                  >
                                    Next
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="offcanvas offcanvas-end management_details"
        tabIndex={-1}
        id="offcanvasExample1"
        aria-labelledby="offcanvasExampleLabel"
      >
        {viewLoad ? (
          <div style={loaderStyle}>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#fff"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="offcanvas-body">
            <div className="row management_main">
              <div className="col-md-12 mb-4">
                <div className="management_profile text-center">
                  <div className="management_pic">
                    <img src="../assets/img/coach/profile_managemnet.png" alt />
                  </div>
                  <div className="management_name">
                    {newData?.details?.school}
                  </div>
                  <a
                    className="management_mail"
                    href="mailto:williamjam@gmail.com"
                  >
                    {newData?.email}
                  </a>
                  <a className="management_number" href="tel:+971 909898998">
                    {newData?.countrycode} {newData?.phonenumber} |{" "}
                    {newData?.firstname} {newData?.lastname}
                  </a>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <div className="student_statistics">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="staticts_head">School Statistics</div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="row statistics_inner justify-content-between">
                        <div className="col-auto ps-0">
                          <span>Class Consistency</span>
                        </div>
                        <div className="col-auto text-end pe-0">
                          <span>0</span>
                        </div>
                      </div>
                      <div className="row statistics_inner justify-content-between">
                        <div className="col-auto ps-0">
                          <span>Total Sessions Booked</span>
                        </div>
                        <div className="col-auto text-end pe-0">
                          <span>0</span>
                        </div>
                      </div>
                      <div className="row statistics_inner justify-content-between">
                        <div className="col-auto ps-0">
                          <span>Total Sessions Coached</span>
                        </div>
                        <div className="col-auto text-end pe-0">
                          <span>0</span>
                        </div>
                      </div>
                      <div className="row statistics_inner justify-content-between">
                        <div className="col-auto ps-0">
                          <span>Total Sessions Owed</span>
                        </div>
                        <div className="col-auto text-end pe-0">
                          <span>0</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 pb-3">
                      <div className="row School_Statistics py-2">
                        <div className="col-md-6">
                          <div className="School_stathead">Player Names</div>
                        </div>
                        <div className="col-md-3 text-center">
                          <div className="School_stathead">Position</div>
                        </div>
                        <div className="col-md-3 text-center">
                          <div className="School_stathead">Rating</div>
                        </div>
                      </div>
                      <div className="row School_stat_data pt-2">
                        <div className="col-md-6">
                          <span>Eleanor Pena</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>C</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>4000</span>
                        </div>
                      </div>
                      <div className="row School_stat_data pt-2">
                        <div className="col-md-6">
                          <span>Eleanor Pena</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>VC</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>4000</span>
                        </div>
                      </div>
                      <div className="row School_stat_data pt-2">
                        <div className="col-md-6">
                          <span>Eleanor Pena</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>P</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>4000</span>
                        </div>
                      </div>
                      <div className="row School_stat_data pt-2">
                        <div className="col-md-6">
                          <span>Eleanor Pena</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>P</span>
                        </div>
                        <div className="col-md-3 text-center">
                          <span>4000</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <CoachHomeFooter />
    </>
  );
};

export default IndividualSession;
